<template>
<BasicModal @close="emit('close')" title="Contact">
  <form @submit.prevent="sendEmail">
    <template v-if="sent">
      <h3>Thanks for reaching out!</h3>
      <p>We'll get back to you as soon as possible. (check your spam folder just in case)</p>
    </template>
    <template v-else>
      <h3>{{ error.code ? error.message : 'Drop us a line!' }}</h3>
      <input v-model="email" type="email" placeholder="Email" />

      <textarea v-model="message" placeholder="Message" rows="5"></textarea>

      <button
        :disabled="!isValid || loading"
        type="submit"
      >
        {{ loading ? 'Sending..' : 'Submit' }}
      </button>
    </template>
  </form>
</BasicModal>
</template>

<script setup>
import { defineEmits, ref, reactive, computed, watchEffect } from 'vue';
import BasicModal from '@/components/BasicModal.vue';
import { validateEmail, minSpin } from '@/util/util';

const loading = ref(false);
const sent = ref(false);
const email = ref('');
const message = ref('');

const error = reactive({ code: 0, message: '' });

const isValid = computed(() => {
  return Boolean(validateEmail(email.value) && message.value);
});

watchEffect(() => {
  console.log(error)
});

const emit = defineEmits(['close']);

async function sendEmail() {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email.value,
      message: message.value,
    }),
  }

  try {
    loading.value = true;
    const response = await minSpin(() => fetch('api/contact', options), 2000);

    if (response.status === 429) {
      error.code = response.status;
      error.message = 'Too many requests. Please try again later.';
      throw new Error(error.message);
    }

    if (!response.ok) {
      error.code = response.status;
      error.message = 'Something went wrong! Please try again.';
      throw new Error(error.message);
    }
    else {
      sent.value = true;
    }
  }
  catch (err) {
    console.error(err);
  }
  finally {
    loading.value = false;
  }
}
</script>

<style scoped lang="scss">
form {
  padding: 20px 40px;
  margin: 0 10px;
  max-width: 450px;
  width: 100%;
  border: 1px solid var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;

  @media (max-width: 450px) {
    padding-left: 12px;
    padding-right: 12px;

    textarea {
      height: 250px !important;
    }
  }

  h3 {
    margin: 0;
    font-size: 18px;
    color: var(--primary-color);
  }

  p {
    font-size: 18px;
    color: white;
    text-align: center;
  }

  input, textarea {
    width: 100%;
    padding: 10px 40px 10px 10px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-family: Anonymous Pro, monospace;
    font-size: 18px;
  }

  button {
    width: 98%;
    @media(min-width: 1024px) {
      width: 80%;
    }
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    color: var(--primary-color);
    text-shadow: 1px 1px 1px black;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
    outline: 1px solid var(--primary-color);
    background-color: var(--primary-dim);

    &:hover, &:focus {
      text-decoration: underline;
    }

    &[disabled] {
      cursor: auto;
      text-decoration: none !important;
      filter: hue-rotate(45deg);
    }
  }
}
</style>

